import React from 'react';
import module from './group.module.sass';

export const groupVariant = {
    horizontal: 'horizontal',
    vertical: 'vertical',
};

const Group = ({style, variant, children}) => {
    switch(variant)  {
        case groupVariant.horizontal:
            return (
                <div style={style} className={module.group + ' ' + module.horizontal}>
                    {children}
                </div>
            );
        case groupVariant.vertical:
            return (
                <div style={style} className={module.group + ' ' + module.vertical}>
                    {children}
                </div>
            );
        default:
            return (
                <div style={style} className={module.group}>
                    {children}
                </div>
            );
    }
};

export default Group;