import React from 'react';
import Sider from "../ui/sider/sider";
import Typography from "../ui/typography/typography";
import Group from "../ui/group/group";
import Input from "../ui/input/input";
import Button from "../ui/button/button";
import useInput from "../hooks/useInput";
import ButtonBlock from "../ui/button/buttonBlock";
import Select from "../ui/select/select";
import {phoneCodes} from "../phoneCodes";
import useEmail from "../hooks/useEmail";
import {UserMethods} from "../methods/user.methods";
import Alert from "../ui/alert/alert";
import Loader from "../ui/loader/loader";
import T from "./t";

const RegistrationComponent = ({toAuth}) => {
    const [loading, setLoading]  =  React.useState(true);
    const firstName = useInput("");
    const lastName = useInput("");
    const email = useInput("");
    const [phoneCode, setPhoneCode] = React.useState("371");
    const phone = useInput("");
    const [error, setError]  =  React.useState('');
    const [ok, setOk]  =  React.useState('');
    const {isEmail, changeEmail} = useEmail();

    React.useEffect(()  =>  {
        if (email.value.length > 4) {
            changeEmail(email.value);
        }
    }, [email.value, changeEmail]);

    React.useEffect(()  =>  {
        setLoading(() => false);
    }, []);

    const sendForm = (firstName, lastName, email, phoneCode, phone) => {
        setLoading(() => true);
        const formData = new FormData();
        formData.append("first_name", firstName);
        formData.append("last_name", lastName);
        formData.append("email", email);
        formData.append("phone_code", phoneCode);
        formData.append("phone", phone);
        UserMethods.registration(formData)
            .then(() => {
                setOk(() => 'Вы успешно зарегистрированы, ваш аккаунт будет активирован после проверки');
            }).catch(err => {
                setError(() => err.response.data.errors ? err.response.data.errors[0] : err.response.data.message === 'Validation error' ? 'Пользователь уже зарегистрирован' : err.response.data.message)
            }).finally(() => {
                setLoading(false)
            });
    }

    React.useEffect(() => {
        if (ok.length > 0)   {
            setTimeout(()  => {
                setOk(() => '');
                toAuth();
            }, 3000);
        }
    }, [ok, toAuth]);

    React.useEffect(() => {
        if (error.length > 0)  {
            setTimeout(()  => {
                setError(() => '');
            }, 3000);
        }
    }, [error]);

    if (loading)  {
        return <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
            <Loader />
        </div>
    }

    return (
        <Sider active={!loading} position={"bottom"}>
            {
                error.length > 0 && <Alert variant={"error"}>{error}</Alert>
            }
            {
                ok.length > 0 && <Alert variant={"success"}><T>{ok}</T></Alert>
            }
            <Typography variant={"h3"}>
                <T>Регистрация</T>
            </Typography>
            <Group variant={"horizontal"}>
                <Input type={"text"} label={<T>Имя</T>} {...firstName} />
                <Input type={"text"} label={<T>Фамилия</T>} {...lastName} />
                <Group style={{alignItems: "flex-start"}}>
                    <Select searchOn defaultValue={"371"} label={<T>Регион</T>} onRes={(e) => setPhoneCode(e)} {...phoneCode} options={phoneCodes} />
                    <Input type={"number"} label={<T>Телефон</T>} {...phone} />
                </Group>
                <Input type={"email"} label={<T>Email</T>} {...email} />
                <Button
                    disabled={
                        firstName.error || firstName.value.length === 0 ||
                        lastName.error || lastName.value.length === 0 ||
                        email.error || email.value.length === 0 ||
                        phone.error || phone.value.length < 8 ||
                        !isEmail
                    }
                    onClick={
                    () => sendForm(
                        firstName.value,
                        lastName.value,
                        email.value,
                        phoneCode,
                        phone.value
                    )
                }
                >
                    <T>Далее</T>
                </Button>
            </Group>
            <Typography>
                <T>Заполните форму и нажмите далее</T>
            </Typography>
            <ButtonBlock>
                <Typography>
                    <T>Есть аккаунт?</T>
                </Typography>
                <Typography onClick={toAuth} variant={"link"}>
                    <T>Авторизуйтесь</T>
                </Typography>
            </ButtonBlock>
        </Sider>
    );
};

export default RegistrationComponent;