import React from 'react';
import HeaderComponent from "./header.component";
import CLOSE from "../icons/close1.svg";
import BigAvatar from "../ui/bigAvatar/bigAvatar";
import AccordionItem from "../ui/accordion/accordionItem";
import MESSAGE from "../icons/message.svg";
import DOC from "../icons/doc.svg";
import Typography from "../ui/typography/typography";
import Accordion from "../ui/accordion/accordion";
import Logout from "../ui/logout/logout";
import Group from "../ui/group/group";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import LangSwitcherComponent from "./langSwitcher.component";
import T from "./t";
import {UserMethods} from "../methods/user.methods";


const SiderContentComponent = ({closeSider, pages}) => {
    const {user_store, config_store} = React.useContext(Context);
    const navigate = useNavigate();
    const [menuList, setMenuList] = React.useState([{id: 0, label: <T>Поддержка</T>, value: <T>Поддержка</T>, icon: MESSAGE, url: "support"}]);
    const [app_version, setAppVersion]  = React.useState('');
    React.useEffect(()  =>  {
        for (const page of pages ){
            setMenuList((prev) => [...prev, { id: page.id, label: page.title, value: page.title, icon: DOC, url: page.pageId ? page.pageId : page.id}]);
        }
    }, [pages]);

    const exitUser = () => {
        UserMethods.logout().then((res)  => {
            localStorage.removeItem("token");
            navigate("/auth");
            closeSider();
        }).catch((err)  =>  {
            console.log(err);
        })
    };

    React.useEffect(() => {
        if (window.cordova) {
            if (window.device.platform === 'Android' || window.device.platform === 'iOS') {
                window.cordova.getAppVersion.getVersionNumber(function (version) {
                    setAppVersion(() => version)
                });
            }
        }

    }, [config_store])


    return (
        <div style={{overflow: 'auto'}}>
            <HeaderComponent img={CLOSE} clickAvatar={closeSider}/>
            <BigAvatar name={user_store.isProfile.first_name + " " + user_store.isProfile.last_name}/>
            <div style={{marginTop: '1.5rem', marginBottom: '5rem', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <LangSwitcherComponent/>
            </div>
            <Accordion style={{marginTop: '3rem', height: 'calc(100vh -  27.5rem)'}} variant={"menu"}>
                {
                    menuList.map((data) => {
                        return (
                            <AccordionItem onClick={() => navigate(`/${data.url}`)} read={data.read} key={data.id}
                                           icon={data.icon} title={data.label}>
                                <Typography>{data.value}</Typography>
                            </AccordionItem>
                        );
                    })
                }
                <Group variant={"vertical"} style={{marginTop: '3rem', alignItems: 'center', justifyContent: 'center'}}>
                    <Logout onClick={exitUser} style={{marginLeft: '-1rem'}} description={<T>Выйти</T>}/>
                    <Typography>
                        <T>Версия</T>: {app_version.length > 0 ? app_version : '1.0.0'}
                    </Typography>
                </Group>
            </Accordion>
        </div>
    );
};

export default observer(SiderContentComponent);